export interface WebsiteConfig {
  title: string;
  description: string;
  coverImage: string;
  logo: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  /**
   * full url, no username
   */
  facebook?: string;
  /**
   * full url, no username
   */
  twitter?: string;
  linkedin?: string;
  medium?: string;
  reddit?: string;
  pinterest?: string;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
  /**
   * name and id of the mailchimp email field
   */
  mailchimpEmailFieldName?: string;
  /**
  /**
   * Meta tag for Google Webmaster Tools
   */
  googleSiteVerification?: string;
  /**
  /**
   * Appears alongside the footer, after the credits
   */
  footer?: string;
}

const config: WebsiteConfig = {
  title: 'noTnoob Dev',
  description: "Backend Engineer | Python, Django, Linux & Rails",
  coverImage: 'img/cover.png',
  logo: 'img/blog-logo.png',
  lang: 'id',
  siteUrl: 'https://dev.notnoob.com',
  facebook: 'https://www.facebook.com/ThenoTnoob',
  twitter: 'https://twitter.com/thenotnoob',
  reddit: 'https://reddit.com/r/notnoob',
  linkedin: 'https://linkedin.com/company/notnoob',
  medium: 'https://notnoob.medium.com',
  pinterest: 'https://pinterest.com/iamnotnoob',
  showSubscribe: true,
  mailchimpAction: 'https://twitter.us15.list-manage.com/subscribe/post?u=0fa07c0dfe52cd882b109e3ba&amp;id=b24c4c6ad9',
  mailchimpName: 'icoldplayer',
  mailchimpEmailFieldName: 'MERGE0',
  // googleSiteVerification: 'GoogleCode',
  // footer: 'is based on Gatsby Casper',
};

export default config;
